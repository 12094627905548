<template>
	<div style="background-color: #f7f8fa; padding: 32px 0 39px 0">
		<div class="myMain width_1200 margin_auto flex justify_content_sb">
			<div class="js-user__menu">
				<div class="js-menu">
					<ul>
						<li @click="$router.push({ path: '/' })" class="pointer">
							<div class="js-menu__nav1">
								<img src="https://staticn.jinse.cn/w/img/abbba16.png" />
								<a class=""> 首页 </a>
							</div>
						</li>
						<li>
							<div class="js-menu__nav1">
								<img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAAAXNSR0IArs4c6QAAAmdJREFUWAntVj1uE0EU/saJqJwmtIELIEFhHyBKYSkX4ASRBTKioOAQFIgCRGS5j5QLRLiIOIBdgAQHCGlJk60QyfJ9o0x43p2xs/ZaSrEjrWb2/X7zZt6857rd7j6AYZ7nO5zvPJxz5xTuTyaTk3lKl0M8wTUOKNPLczyWrHM44zRGC6OtPn6IFoYjoF9VwdwqExQBPQr/ds6P8SC7wHvkeJmDriPDgVAdPre38cY9xx+JtJYFI+WUrgfzGyeMyCAFxut7/xhkkuUGRIsiF2OVocgQyN5dbUjWR5MKrtPp8P//mE6njGR6LJL3d+YK321keGfOafQt56+yzMjtkv+O8+299ce3gaebaddLcniBi2Dam3jmDnBhLB7lI3zJ/uJbACUdR911HFnPOOadZWRmwXi2aOJZWa57tQPijn1qB0fhmMK/nYs86dYOyDpcZl07IO76zALhrnftv10XedKtHRAdjmecKptG2LY0rUVTphXo4/qzjOXAXeF1yDRGYUfZlB3Opj1p5bSXbvFdKSBe+Bt7ty4P8ZFABguVjQCP69PWC7xax5HB1ybg1Pibu2T6n0pHQq2bqj1XIcVM6apQth9iX7v2L3DCgHiS8bKhuFK2nzKcsOPJof1IyQiUjgAsB3T6gd9Pymb6tBZNPMmESp+y1dCbCFSJgFrYWnvqVe3pHarc4GvHN+3rMLL7lezV3lOn+uwI8BJJumt5qUueKhBKxTVWm6y9qrWvqr17F6EGkD3+2LqJUCwqltZEyEYjti69Q1XfmZhRS6tq7/4d2TLta4hATDdGC/KLZukqQnX31CvZ+wfsrfBbAIrtsAAAAABJRU5ErkJggg=="
								/>
								<span>功能</span>
							</div>
							<a
								@click="$router.push({})"
								class="js-menu__nav2 nuxt-link-exact-active nuxt-link-active active pointer"
							>
								发布文章
							</a>
							<!---->
							<!-- <a  href="/userColumn/activity" class="js-menu__nav2">
                            发布活动
                        </a> -->
						</li>
						<li>
							<div class="js-menu__nav1">
								<img
									src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAiCAYAAAA3WXuFAAAAAXNSR0IArs4c6QAAAVpJREFUWAljZMADzM3NZf7+/dv1//9/B0ZGRjYgvY+Nja3q+PHjd/Boo0iKEZduqGMuAh0hhKbmDZBvcfbs2bto4lThMpqZmdkCQ2Em0DQNIMbpQKrYhtuQ/0Cp68zMzBmMxsbG94EcBdxq6SrzgImu1hFhGRMwmOKA6q4DMSjYBgrAoiwOZ5qxtLRU+fXr13GgC0XQXPke6AnjU6dOgaKa6oAZl4lPnjx5JyUltQ4oLwnEUkD8A4g3Ax0TRivHAM0fBaMhQHEI4MxlFJsMNICcupBmDiK3LhytywgkhdG6DFcAEa7LcOkkVpzcuhBnXUasxbjUjdaFuEJmVHw0BAY6BLBWrsi1NMiBwF7rAWDTtezkyZNPaO1gDAfhqqWBjnoHdJQ+rR01WtsTiPLR2h5XAOGu7Qc6UWPU9k+fPv0kKyu7DDguBOqxgrrRX4A5bAswhwXTOoeBgg8A4Fa2xnMsq/IAAAAASUVORK5CYII="
								/>
								<span>管理</span>
							</div>
							<a class="js-menu__nav2"> 我的文章 </a>
							<!---->
							<!-- <a  href="/userColumn/activityList" class="js-menu__nav2">
                            我的活动
                        </a> -->
						</li>
						<!-- <li >
                        <div  class="js-menu__nav1"><img 
                                src="https://staticn.jinse.cn/w/img/b093a38.png"> <span >设置</span>
                        </div> 
                    </li> -->
					</ul>
				</div>
			</div>
			<div class="myMain_r">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return {};
	},
	methods: {},
};
</script>

<style scoped>
.myMain_r {
	width: 881px;
	background: #ffffff;
	border-radius: 0px 0px 0px 0px;
	opacity: 1;
	border: 1px solid #ffffff;
	padding: 41px 0 0 41px;
}
.js-loading {
	margin: 34px auto;
}

.js-loading .js-loadMoreBtn {
	width: 100%;
	height: 48px;
	border: 1px solid #f5f5f5;
	border-radius: 2px;
	background: #f5f5f5;
	text-align: center;
	cursor: pointer;
}

.js-loading .js-loadMoreBtn,
.js-loading .js-noData {
	display: flex;
	justify-content: center;
	align-items: center;
}

.js-loading .js-noData {
	flex-direction: column;
	min-height: 400px;
}

.js-loading .js-noData img {
	margin: 20px 0;
}

.js-loading .js-noData span {
	font-size: 15px;
	color: #999;
}

.js-list-loading {
	margin-top: 15px;
	text-align: center;
	font-size: 18px;
	height: 48px;
	padding-top: 10px;
	box-sizing: border-box;
}

.js-list-loading div {
	width: 12px;
	height: 12px;
	margin-left: 10px;
	background: #999;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: bouncedelay 1.4s ease-in-out infinite;
	animation: bouncedelay 1.4s ease-in-out infinite;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.js-list-loading div:first-child {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.js-list-loading div:nth-child(2) {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

@keyframes bouncedelay {
	0%,
	80%,
	to {
		transform: scale(0);
		-webkit-transform: scale(0);
	}

	40% {
		transform: scale(1);
		-webkit-transform: scale(1);
	}
}

.js-no-more {
	text-align: center;
	font-size: 16px;
}

.js-menu {
	width: 260px;
	margin-right: 20px;
	background: #fff;
	padding: 43px 0;
	border-radius: 4px;
}

.js-menu li {
	margin-bottom: 40px;
}

.js-menu a.active,
.js-menu a:hover {
	color: #0056ff;
}

.js-menu .js-menu__nav1 {
	padding: 13px 0 13px 50px;
	display: flex;
	font-size: 20px;
	font-weight: 600;
	align-items: center;
}

.js-menu .js-menu__nav1 img {
	width: 20px;
	height: 20px;
	margin-right: 15px;
}

.js-menu .js-menu__nav2 {
	display: block;
	padding: 13px 0 13px 85px;
	font-size: 16px;
	font-weight: 500;
}

.js-user-app {
	width: 100%;
	min-height: 90vh;
	height: auto;
	background-color: #f7f8fa;
}

.js-user-app .js-list-loading {
	margin-top: 300px;
}

.js-user-app .js-user {
	width: 1200px;
	min-height: 800px;
	margin: 30px auto 50px;
}

.js-user-app .js-user:after {
	content: "";
	display: block;
	visibility: hidden;
	height: 0;
	clear: both;
}

.js-user-app .js-user .js-user__menu {
	width: 260px;
	min-height: 992px;
	float: left;
	background: #fff;
}

.js-user-app .js-user .js-user__menu .chose-active {
	background: #f7f8fa;
	color: #000;
	font-weight: 700;
}

.js-user-app .js-user .js-user__menu .js-user__menu-avatar {
	width: 100px;
	height: 100px;
	margin: 61px auto;
}

.js-user-app .js-user .js-user__menu .js-user__menu-avatar img {
	width: 100%;
	height: 100%;
	border-radius: 50%;
}

.js-user-app .js-user .js-user__menu .js-user__menu-item {
	width: 260px;
	height: 50px;
	line-height: 50px;
	color: #333;
	font-size: 16px;
}

.js-user-app .js-user .js-user__menu .js-user__menu-item li {
	padding-left: 80px;
}

.js-user-app .js-user .js-user__menu .js-user__menu-item li:hover {
	cursor: pointer;
	background: #f7f8fa;
	color: #000;
	font-weight: 700;
}

.js-user-app
	.js-user
	.js-user__menu
	.js-user__menu-item
	li
	.js-user__menu-item_icon {
	width: 21px;
	height: 21px;
	vertical-align: middle;
}

.js-user-app .js-user .js-user__content {
	width: 920px;
	float: right;
	background: #fff;
	min-height: 992px;
}

.js-user-app .js-user .js-user__content .js-list-loading {
	margin-top: 300px;
}

.js-user-rights {
	display: flex;
	height: 130px;
	background: #f7f7fa;
	border-radius: 4px;
	padding: 0 0 20px;
}

.js-user-rights .js-user-right-coonents {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #333;
	padding: 0 13px;
	background: #fff4dd;
}

.js-user-rights .js-user-right-titles {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	font-size: 18px;
	display: flex;
	align-items: center;
	color: #fff;
	height: 130px;
	background: #ffb100;
	border-radius: 4px;
	white-space: nowrap;
	padding: 0 13px;
}

.js-user-rights .js-user-right-img {
	width: 130px;
}

.js-user-right {
	display: flex;
	height: 70px;
	background: #f7f7fa;
	border-radius: 4px;
	padding: 0 0 20px;
}

.js-user-right .js-user-right-title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-weight: 600;
	font-size: 18px;
	display: flex;
	align-items: center;
	color: #fff;
	height: 70px;
	background: #ffb100;
	border-radius: 4px;
	white-space: nowrap;
	padding: 0 13px;
}

.js-user-right .js-user-right-coonent {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	line-height: 20px;
	display: flex;
	align-items: center;
	color: #333;
	padding: 0 13px;
	background: #fff4dd;
}
</style>
